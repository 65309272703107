$base-light-font-family: 'HelveticaNeueLTStd-UltLt', 'Microsoft YaHei', 'Microsoft JhengHei', 'Arial', 'Sans-Serif';
$base-font-family: 'HelveticaNeueLTStd45Light', 'Microsoft YaHei', 'Microsoft JhengHei', 'Arial', 'Sans-Serif';
$base-font-family-roman: 'HelveticaNeueLTStd55Roman', 'Microsoft YaHei', 'Microsoft JhengHei', 'Arial', 'Sans-Serif';
$base-italic-font-family: 'HelveticaNeueLTStd56Italic', 'Microsoft YaHei', 'Microsoft JhengHei', 'Arial', 'Sans-Serif';
$base-black-font-family: 'HelveticaNeueLTStd95Black', 'Microsoft YaHei', 'Microsoft JhengHei', 'Arial', 'Sans-Serif';
$base-bold-font-family: 'HelveticaNeueLTStd65Medium', 'Verdana', 'Microsoft YaHei', 'Microsoft JhengHei', 'Arial',
  'Sans-Serif';
$base-link-font-family: $base-font-family;
$base-button-font-family: $base-bold-font-family;
$font-family-GTSectraFineRegular: 'GTSectraFineRegular', 'Microsoft YaHei', 'Microsoft JhengHei';
$font-family-GTSectraFineMediumItalic: 'GTSectraFineMediumItalic', 'Microsoft YaHei', 'Microsoft JhengHei';
$font-family-VisueltRegular: 'VisueltRegular', 'HelveticaNeueLTStd55Roman', 'Segoe UI', 'sans-serif', 'Microsoft YaHei',
  'Microsoft JhengHei';
$font-family-VisueltLight: 'VisueltLight', 'Helvetica Neue', 'Segoe UI', 'sans-serif', 'Microsoft YaHei',
  'Microsoft JhengHei';
