body {
  font-family: 'Microsoft YaHei', 'Microsoft JhengHei', 'Arial', 'Sans-Serif';
}

html,
button,
input,
select,
textarea {
  font-family: 'Microsoft YaHei', 'Microsoft JhengHei', 'Arial', 'Sans-Serif';
}

#ff_spp #product-results .result .prod_return h3 {
  font-family: 'Microsoft YaHei', 'Microsoft JhengHei', 'Arial', 'Sans-Serif';
}

.device-mobile {
  .submit_btn input[type='submit'] {
    font-family: 'Microsoft YaHei', 'Microsoft JhengHei', 'Arial', 'Sans-Serif';
  }
}

.section-international-sites {
  .field-mobile-template {
    .basic-content-mobile-v1 {
      .content {
        max-height: none;
      }
    }
  }
}

.olapic-v1__header {
  width: 100%;
  max-width: 960px;
  margin: 0px auto 10px;
}

.header-gnav-links {
  &:last-child {
    margin-bottom: 60px;
  }
}

.wrapper {
  #search-wrapper {
    #results-container {
      display: block;
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    font-family: $base-bold-font-family;
  }
}

.cart-item-rows {
  .prod-info {
    .dis-qty {
      padding-top: 5px;
    }
  }
}
.checkout-back-btn {
  display: none;
}
.checkout {
  &.shipping,
  &.review {
    li[id='offer_bogo..'] {
      display: none;
    }
  }
}

#accountqrcode {
  padding: 20px;
  margin-top: 40px;
}

#signin {
  .checkout {
    .social-login {
      .fb-login-button {
        opacity: 1;
      }
    }
  }
}

.field-collection-slides {
  .product-grid-recommendations-formatter {
    .sd-product-grid {
      width: 100%;
      max-width: $cr19-max-width-xlarge;
    }
  }
}

.text-link--style-2-bold,
a.text-link--style-2-bold {
  font-weight: bold;
}
