.clinical-reality {
  .mobile_hidden {
    @include start-breakpoint($medium-m) {
      max-height: 750px;
    }
    @include start-breakpoint($medium-max) {
      max-height: inherit;
    }
  }
  .pc_hidden {
    @include start-breakpoint($small-m) {
      max-height: 500px;
    }
  }
}

.section-clinicalreality {
  .nav-promo {
    @include start-breakpoint($medium-min) {
      display: none;
    }
  }
}
