@if $password_strength {
  .password-field {
    &__info {
      @media #{$medium-up} {
        #registration & {
          bottom: 40px;
          #{$ldirection}: 5%;
        }
        #confirm & {
          bottom: 45px;
          #{$ldirection}: 11%;
          top: auto;
        }
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color-btn-grey;
        display: flex;
      }
    }
    &.registration-page__content {
      #foreground-node & {
        .profile-password-update__button {
          &.disabled {
            opacity: 0.4;
            cursor: not-allowed;
          }
        }
      }
    }
  }
  #gnav_signin {
    .signin_container {
      .register {
        .login-email {
          #register-form {
            .password-field {
              overflow: visible;
            }
          }
        }
      }
    }
  }
}
