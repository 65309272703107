/* Email signup waitlist Popup */
@font-face {
  font-family: 'HelveticaNeueLTStd45Medium';
  src: url('#{$netstorage-font-path}HelveticaNeue/HelveticaNeue-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTStd45Light';
  src: url('#{$netstorage-font-path}HelveticaNeue/HelveticaNeue-Light.woff2') format('woff2');
  font-display: swap;
}

ul {
  &.error {
    margin-top: 3px;
  }
  &.error_messages li {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    color: #ef6ea8;
    font-size: 12px !important;
    line-height: 1.3em;
    margin: 0 !important;
    padding: 0 !important;
    text-transform: lowercase;
  }
}

#foreground-node {
  background: #f5f5f5;
  left: 0 !important;
}

html body {
  color: #1f1f1f;
  background: none;
  min-width: 230px;
  font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
  font-size: 13px;
}

.tiny-waitlist-overlay {
  width: 100%;
  p {
    color: #000000;
  }
  .field {
    margin-bottom: 15px;
    margin-top: 8px;
  }
  form .field label {
    color: #1f1f1f;
    font-weight: normal;
    text-transform: capitalize;
    margin-right: 10px;
    margin-bottom: 8px;
    font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
  }
  .email-address label {
    margin-bottom: 2px;
  }
  form .field input {
    border: 1px solid green;
    height: 30px;
    color: #1f1f1f;
    width: 100%;
  }
  .align-r input {
    font-family: 'HelveticaNeueLTStd45Mideum', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
    background: #5db997;
    color: #ffffff;
    display: inline-block;
    float: right;
    font-size: 16px;
    font-weight: bold;
    height: 34px;
    padding: 0px 13px;
    text-transform: uppercase;
    text-align: center;
    border: 0 none;
    width: 100%;
  }
}

.waitlist_overlay_content {
  text-align: center;
}
