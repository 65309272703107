.elc-vto-foundation {
  .elc-vto {
    &-perfect-shade-label {
      font-size: 13px;
      width: auto;
    }
    &-custom-shade-picker {
      width: 100%;
    }
    &-custom-shade-picker-item-name {
      width: 70px;
    }
  }
  .vto-foundation-perfect-shade {
    .slick-slide:nth-child(2) {
      flex-basis: 27%;
    }
  }
}
