.body_container {
  .main_container {
    .address-book-info {
      .account-block-container {
        .address_item {
          margin-left: 0px;
        }
      }
    }
    .checkout {
      #main.multi {
        .cart-item-rows {
          .prod-info {
            .prod-desc {
              h4 {
                font-size: 17px;
              }
            }
          }
        }
      }
      .signin-wrapper {
        .sign-in-panel {
          .signin_account_subheader {
            margin-bottom: 10px;
          }
          #new-user-input-submit {
            margin-bottom: 20px;
          }
          .return-user__submit {
            line-height: normal;
          }
        }
      }
    }
    .samples-page {
      .m_samples #navigation .sample-button input#add-samples {
        line-height: 45px;
        margin: 0;
      }
      .m_samples {
        .pick_x {
          .sample-products {
            li.sample-box {
              height: auto;
            }
          }
        }
      }
    }
    #container {
      #change-password {
        #password_reset {
          .label {
            width: 100%;
            > span {
              width: 100%;
              float: left;
              margin: 0 0 5px 0;
            }
          }
        }
      }
    }
    #header {
      .block-template-search-mobile-v2 {
        float: #{$ldirection};
      }
    }
    .spp_product_status {
      .coming_soon_stock_msg {
        .temp_out_of_stock_msg_wrapper {
          a.btn-comingsoon {
            font-size: 11px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

#shipping {
  .main_container {
    #wrapper {
      margin-top: 20px;
      h2 {
        font-size: 48px;
      }
    }
  }
}

.elc-user-state-anonymous {
  .menu-mlid-79c0ac58-3c31-11e3-814d-000c29cc69ea {
    display: none;
  }
}

.elc-user-state-logged-in {
  .menu-mlid-296ae432-3c2f-11e3-814d-000c29cc69ea {
    display: none;
  }
}

.spp-product {
  .order_arrival {
    #order_arrival_info {
      display: none;
    }
  }
}

#shipping {
  #wrapper {
    #storeApi {
      .store-submit {
        background-color: #5dba98;
        text-transform: uppercase;
        color: white;
        line-height: 1.5;
        font-size: 0.9em;
        border: 0;
        padding: 4px 10px 3px;
        display: inline-block;
      }
    }
  }
}

#footer {
  .node-elc-nodeblock {
    .field-mobile-template {
      .social-links {
        height: 25px;
        width: 205px;
        margin: 15px auto;
      }
      .footer-trustmark {
        float: none;
        width: 100px;
        margin: 0 auto;
      }
    }
  }
}

.device-mobile.section-store-locator {
  a.button {
    padding: 5px 0;
  }
  .store-locator-mobile-v1 {
    .map_container,
    .map_canvas {
      width: 100% !important;
      .gm-style-iw,
      .gm-style-iw > div {
        max-width: 200px !important;
        min-width: 150px !important;
      }
    }
    .locations_form_panel {
      .address_section_container {
        .city_container,
        .door_container {
          select {
            margin: 10px 0px;
            width: 90%;
            display: block;
          }
        }
      }
    }
  }
}

#order_detail {
  .order-confirmation {
    .order-detail {
      padding-left: 10px;
    }
  }
}

#ff_spp {
  display: block;
}

.power-couples-row-mobile-v1 {
  .product {
    .product-title a {
      display: block;
    }
  }
}

.three-step-shopbystep-mobile-v1 {
  h3 {
    max-height: 140px !important;
    a {
      display: block;
    }
  }
}

.device-mobile .gnav-cart,
.device-mobile .gnav-drop-down,
.device-mobile #node-30776 {
  position: static;
}

.device-mobile #header .btn_navigation {
  position: relative;
  z-index: 999;
}

.body_container .main_container #header .block-nodeblock-30258,
.body_container .main_container #header .block-nodeblock-30260,
.body_container .main_container #header .block-nodeblock-31314,
.body_container .main_container #header .block-nodeblock-30242 {
  float: left;
}

.popup-offer-v1 {
  .signup-forms {
    float: left;
    width: 60%;
    .form-submit-wrap {
      .form-text {
        width: 100% !important;
        padding: 0px;
        margin: 5px 0px 0px 41px;
      }
      .form-submit {
        width: 50% !important;
        margin-left: 1px !important;
        position: relative;
        left: 70px;
        margin-top: 12px;
      }
    }
    .error {
      margin-left: 20px;
    }
    .popover-newsletter-signup {
      #popover-newsletter-signup {
        .popup-offer-msg {
          margin-left: 40px;
          margin-top: 10px;
          width: 250px;
        }
      }
    }
  }
  h2 {
    font-size: 18px !important;
    margin: 5px !important;
  }
  .terms_button_close {
    background: transparent url('/sites/clinique/themes/cl_base/img/icon-close.png') no-repeat
      scroll 0 0;
    height: 22px;
    position: absolute;
    right: 5px;
    width: 22px;
  }
  .popup-offer-close {
    right: 5px !important;
  }
  .terms_message {
    .terms_title {
      text-decoration: underline;
      text-align: center;
      list-style-type: none;
    }
    p {
      text-align: left;
      list-style-type: disc;
      display: list-item;
      margin: 14px;
      font-size: 14px !important;
    }
  }
  .outer-wrap {
    padding: 5px 10px !important;
    height: 280px !important;
  }
  .signup_popup_image {
    float: left;
    img {
      width: 113px;
      height: auto;
    }
  }
  .subheading {
    p {
      line-height: 1.3;
    }
    .signup_terms_popup {
      text-decoration: underline;
      text-align: left;
    }
  }
  .signedup {
    padding: 50px !important;
    .subheading {
      p {
        padding: 10px;
      }
    }
  }
  .thankyou {
    padding: 50px !important;
    .subheading {
      p {
        padding: 20px;
      }
    }
  }
}

body#viewcart.device-mobile {
  #top {
    position: relative;
  }
}

.section-ebc-landing-page {
  .ebc-grid-item__date {
    display: none;
  }
  .ebc-grid-item__expando-date {
    display: none;
  }
}

.module-how-use {
  display: block;
}

#how-use {
  li.box {
    width: 145px;
    margin: 0 5px 10px 0 !important;
    &:nth-child(1),
    &:nth-child(3) {
      float: left;
    }
    &:nth-child(2),
    &:nth-child(4) {
      float: right;
    }
    &:nth-child(5) {
      float: none;
    }
  }
}

.page-node-47598 {
  .body_container {
    .block {
      .diagnostics-merch-window {
        .screenshot {
          width: 100%;
          margin: 0 3% 80px 0;
        }
      }
    }
    .block-nodeblock-30732 {
      .diagnostics-merch-window.has-image {
        background-position-y: 342px;
        background-size: 100%;
        height: 1010px;
        padding: 0;
        position: relative;
        .content {
          padding: 0 5% 0 0;
          margin: 0 0 15px 0;
          position: absolute;
          top: 830px;
          left: 36px;
        }
      }
    }
    .block-nodeblock-41754 {
      .diagnostics-merch-window.has-image {
        background-position-y: 260px;
        background-position-x: 35px;
        background-size: 100%;
        height: 800px;
        padding: 0;
        position: relative;
        .content {
          padding: 0 5% 0 0;
          margin: 0 0 15px 0;
          position: absolute;
          top: 630px;
          left: 36px;
        }
      }
    }
  }
}

@media screen and (device-aspect-ratio: 40/71) and (orientation: portrait) {
  .page-node-47598 {
    .body_container {
      .block-nodeblock-30732 {
        .diagnostics-merch-window.has-image {
          background-position-y: 250px;
          height: 850px;
          .content {
            top: 670px;
          }
        }
      }
      .block-nodeblock-41754 {
        .diagnostics-merch-window.has-image {
          background-position-y: 200px;
          background-position-x: 35px;
          height: 680px;
          .content {
            top: 515px;
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .page-node-47598 {
    .body_container {
      .block-nodeblock-30732 {
        .diagnostics-merch-window.has-image {
          background-position-y: 342px;
          height: 1010px;
        }
      }
      .block-nodeblock-41754 {
        .diagnostics-merch-window.has-image {
          background-position-y: 260px;
          background-position-x: 35px;
          height: 800px;
          .content {
            top: 625px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #top {
    #header {
      .block-template-gnav-utility-button-mobile-v1 {
        .gnav-utility-button-v1 {
          border-right: none;
        }
      }
      .block-template-gnav-cart-v1 {
        float: right;
      }
      #review &,
      #samples &,
      #shipping & {
        .block-template-site-logo-responsive-v1 {
          margin: 0 auto;
          width: 100%;
        }
      }
      .block-template-site-logo-responsive-v1 {
        width: 45%;
      }
    }
  }
  .device-mobile {
    .site-logo-responsive-v1 {
      padding: 0 !important;
    }
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .page-node-47598 {
    .body_container {
      .block-nodeblock-30732 {
        .diagnostics-merch-window.has-image {
          background-position-y: 342px;
          height: 1010px;
          .content {
            top: 888px;
          }
        }
      }
      .block-nodeblock-41754 {
        .diagnostics-merch-window.has-image {
          background-position-y: 260px;
          background-position-x: 35px;
          height: 800px;
          .content {
            top: 685px;
          }
        }
      }
    }
  }
}

@media screen and (orientation: landscape) {
  .page-node-47598 {
    .body_container {
      .block-nodeblock-30732 {
        .diagnostics-merch-window.has-image {
          background-position-y: 342px;
          height: 1400px;
          .content {
            top: 1250px;
            left: 130px;
          }
        }
      }
      .block-nodeblock-41754 {
        .diagnostics-merch-window.has-image {
          background-position-y: 380px;
          background-position-x: 80px;
          height: 1200px;
          .content {
            top: 1050px;
            left: 170px;
          }
        }
      }
    }
  }
}

.m_samples {
  #sample-controls {
    #navigation {
      height: auto;
    }
  }
}

.olapic-carousel-list-container .olapic-carousel-partial-container {
  background-image: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_MOBILE_Chinese.jpg');
}

.device-mobile .product-list {
  .offer_message {
    clear: both;
    .product-offer {
      margin: 10px 10px;
      vertical-align: middle;
      text-align: center;
      background: #f5f5f5;
      padding: 20px 10px;
      .product-offer__header {
        padding-bottom: 5px;
        color: #46ab8c;
        font-size: 22px;
        text-align: center;
        font-weight: bold;
      }
      .product-offer__text {
        border-top: 1px solid #7b7b7b;
        padding-top: 10px;
        text-align: left;
        margin-left: 15px;
      }
    }
  }
}

.appt-book {
  .appt-book-page-header {
    .appt-book-page-header-content {
      width: 35%;
      .section-header__inner {
        line-height: 1.1;
      }
    }
  }
  #appointment-book-sections {
    margin-top: 85px;
    #booking-step1 {
      .artist-select-container {
        display: none !important;
      }
    }
    #booking-step3 {
      .registration__terms.error {
        color: #ef6ea8;
      }
    }
  }
}

#popover_container {
  #hppopup {
    img {
      width: 100%;
    }
  }
}

.mobile-signup {
  display: none;
}

section {
  &.clinique-spinner &#game-win-box {
    display: block;
    .win-box-btns {
      display: block;
      .try-again-btn {
        margin-top: 15px;
      }
    }
    div.win-info {
      h2 {
        font-size: 30px;
      }
    }
    .pop-up-tcs {
      a {
        text-decoration: underline;
      }
    }
  }
}

#clinique-cny {
  &.main-clinique-cny {
    font-family: $base-font-family;
  }
}

.zdc {
  &__description {
    .button {
      padding: 12px;
    }
  }
  &_popup {
    &.overlay-container {
      width: 100% !important;
      .zdc {
        &__popup {
          &--content {
            padding-top: 50px;
            .button {
              padding: 12px;
            }
          }
        }
      }
    }
  }
}

.elc-product-carousel-container {
  .elc-product-brief {
    .elc-product-image-section {
      .elc-product-images-wrapper {
        min-height: auto;
      }
    }
  }
}

#landing {
  .account-header-panel {
    .outer-wrap header {
      display: none;
    }
  }
}
