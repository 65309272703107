.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

.margin_top_bottom {
  margin: 0.8em 0;
}

.padding_top_left {
  padding: 10px 0px 5px 10px;
}

.padding_right_left {
  padding: 5px 10px 5px 10px;
}

.navigation_container {
  .menu {
    li {
      &.parent-auto-ship {
        display: none;
      }
    }
  }
}

a.alt-button {
  font-size: 0.9em;
  background-color: $color-white;
  border: 1px solid $color-cl-green-2-darker;
  color: $color-cl-green-2-darker;
  height: 29px;
  line-height: 29px;
  &:hover {
    background-color: $color-white;
  }
}

.device-mobile {
  #foreground-node {
    .close-container {
      position: absolute;
    }
  }
}
