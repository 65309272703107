/* Account Index Page and  Checkout */
.checkout--single-page,
#signin-block {
  .social-login {
    &__email-opt-in {
      @media #{$medium-up} {
        input[type='checkbox'] {
          margin: 9px 10px 0 0;
        }
      }
      @media #{$large-up} {
        input[type='checkbox'] {
          margin: 0;
        }
      }
    }
    &__terms {
      @media #{$medium-up} {
        padding: 0;
        text-align: $ldirection;
      }
    }
  }
}

#signin {
  #signin-block {
    .social-login {
      .social-login__email-opt-in label {
        margin: 6px 0 0;
        color: $color-cl-darker-grey;
        @media #{$large-up} {
          margin: 2px 0 0 9px;
        }
      }
      &__email-opt-in {
        @media #{$medium-up} {
          input[type='checkbox'] {
            margin-top: 8px;
          }
        }
        @media #{$large-up} {
          input[type='checkbox'] {
            margin-top: 4px;
          }
        }
      }
    }
  }
}
/* Profile Preference */
#registration-wrapper {
  .social-info {
    &__connect {
      .fb-login-button {
        padding-#{$ldirection}: 10px;
        vertical-align: middle;
      }
    }
  }
}
/* Order Confirmation */
#main.multi {
  .confirmation-panel {
    .social-login {
      &__container {
        h2 {
          padding: 0;
        }
      }
    }
  }
}

.social-login__container {
  .social-login__title,
  .social-login__info {
    text-align: #{$ldirection};
  }
  .social-login__contents {
    .social-login__email-opt-in {
      .social-login__opt-in-label {
        margin: 5px 0;
        color: $color-cl-darker-grey;
      }
    }
  }
}
/* Gnav social login */
.elc-user-state-logged-in {
  .gnav-my-account-v1 {
    .social-login.gnav {
      .social-login__container {
        display: none;
      }
    }
  }
}
/* Confirmation Page */
#confirm {
  .social-login {
    margin-top: 15px;
    border-top: 1px solid $color-shade-grey;
    &__email-opt-in {
      margin-bottom: 10px;
      @media #{$medium-up} {
        input[type='checkbox'] {
          margin-top: 5px;
        }
      }
      @media #{$large-up} {
        input[type='checkbox'] {
          margin-top: 4px;
        }
      }
    }
  }
}

#gnav_signin {
  .social-login {
    padding-bottom: 20px;
  }
}
/* Book Appointment */
.appointments-container {
  .sign-in-container {
    .sign-in-form-container {
      .social-login {
        .social-login__email-opt-in {
          display: table;
          margin: 0;
          padding: 20px 0 10px;
          input[type='checkbox'] {
            height: auto;
            width: auto;
            margin: 0;
          }
          .social-login__opt-in-label {
            display: table-cell;
            margin: 0;
            padding-#{$ldirection}: 10px;
          }
        }
      }
    }
  }
}
